console.log('JS start');

/*
 MENU
*/

const hamburgerBtn = document.querySelector('.hamburger-menu');
const menu = document.querySelector('.menu');
const body = document.querySelector('body');

hamburgerBtn.addEventListener('click', () => {
  console.log('ham btn clicked');
  hamburgerBtn.classList.toggle('hamburger-menu--active');
  menu.classList.toggle('menu--active');
  body.classList.toggle('body-menu--active');
});

// クラスを削除
const removeMenuClass = () => {
  hamburgerBtn.classList.remove('hamburger-menu--active');
  menu.classList.remove('menu--active');
  body.classList.remove('body-menu--active');
}

// 1080px以上のとき、removeMenuClassをON
const mqs = window.matchMedia('(max-width: 1080px)');
mqs.addListener(watchViewport);

function watchViewport(mqs){
  if (!mqs.matches === true) {
    removeMenuClass();
  }
}
// 初期読み込み時用
watchViewport(mqs);

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener("click", e => {

  const target = e.target;
  // clickした要素がclass属性、js-smooth-scrollを含まない場合は処理を中断
  if (!target.classList.contains("js-smooth-scroll")) return;
  e.preventDefault();
  const targetId = target.hash;
  const targetElement = document.querySelector(targetId);
  // 画面上部から要素までの距離
  const rectTop = targetElement.getBoundingClientRect().top;
  // 現在のスクロール距離
  const offsetTop = window.pageYOffset;
  // ヘッダーの高さ
  const headerHeight = document.querySelector('.header').clientHeight;
  // スクロール位置に持たせるバッファ
  const buffer = headerHeight;
  console.log(headerHeight)
  const top = rectTop + offsetTop - buffer;

  window.scrollTo({
    top,
    behavior: "smooth"
  });

  removeMenuClass();
});


/*
 ダウンロード集にスクロール
*/

// if(document.body.classList.contains('services')) {
if(document.querySelector('.header')) {

  if(!document.body.classList.contains('registered-patients')) {
    let headerDownloadBtns = document.querySelectorAll('.js-download-scroll');

    const headerDLBtnsArr = Array.prototype.slice.call(headerDownloadBtns);

    headerDLBtnsArr.forEach((headerDownloadBtn) => {
      headerDownloadBtn.addEventListener('click', function() {
        let value = 1;
        sessionStorage.setItem('download', value);
      });
    })
  }

  if(document.body.classList.contains('registered-patients')) {
    let storageValue = sessionStorage.getItem('download');

    var element = document.querySelector('#download-pdf');
    var rect = element.getBoundingClientRect();
    const headerHeight = document.querySelector('.header').clientHeight;
    // スクロール位置に持たせるバッファ
    const buffer = headerHeight;
    var elemtop = rect.top + window.pageYOffset - buffer;

    const scrollToDownload = () => {
      document.documentElement.scrollTop = elemtop;
    };

    if (storageValue == 1) {
      scrollToDownload();
      sessionStorage.removeItem('download');
    }
  };
}

/*
 フェードインアニメーション
*/
const elementFadeIn = () => {

  var element = document.getElementsByClassName('js-fadein');
  if(!element) return; // 要素がなかったら処理をキャンセル

  var showTiming = window.innerHeight > 768 ? 50 : 20;
  var scrollY = window.pageYOffset;
  var windowH = window.innerHeight;

  for(var i=0;i<element.length;i++) { var elemClientRect = element[i].getBoundingClientRect(); var elemY = scrollY + elemClientRect.top; if(scrollY + windowH - showTiming > elemY) {
      element[i].classList.add('is-show');
    } else if(scrollY + windowH < elemY) {
      // 上にスクロールして再度非表示にする場合はこちらを記述
      // element[i].classList.remove('is-show');
    }
  }
}

// const ua = navigator.userAgent;
// if(ua.indexOf('Trident') !== -1) {
//   //IE 11のときの挙動
//   const allFadeinElements = document.getElementsByClassName('js-fadein');
//   allFadeinElements.forEach((elem) => {
//     elem.classList.add('is-show');
//   });

// } else {
//   elementFadeIn();
//   window.addEventListener('scroll', elementFadeIn);
// }

elementFadeIn();
window.addEventListener('scroll', elementFadeIn);

/*
 TOPに戻るボタン
*/
scrollTop('to-top', 500);

function scrollTop(elem,duration) {
  let target = document.getElementById(elem);
  target.addEventListener('click', function() {
    let currentY = window.pageYOffset;
    let step = duration/currentY > 1 ? 10 : 100;
    let timeStep = duration/currentY * step;
    let intervalID = setInterval(scrollUp, timeStep);

    function scrollUp(){
      currentY = window.pageYOffset;
      if(currentY === 0) {
          clearInterval(intervalID);
      } else {
          scrollBy( 0, -step );
      }
    }
  });
}
